import Cookies from 'js-cookie';
import '../Home.css';
import authGet from "../../functions/authGet";
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const ClassOverview = () => {
  const userName = Cookies.get('user_name');
  const userId = Cookies.get('user_id'); // Assuming user_id is stored in cookies
  const navigate = useNavigate(); // Hook for navigation
  const [assignmentData, setAssignmentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const classId = pathSegments[2]; // 'class_id' is the third segment
  const assignmentId = pathSegments[3]; // 'class_id' is the third segment

  useEffect(() => {
    const fetchInformation = async () => {
      try {
        const response = await authGet(`${serverUrl}/teacher/${classId}/overview/assignment/${assignmentId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAssignmentData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInformation();
  }, []);

  const handleChatClick = (chatId) => {
    navigate(`/teacher/chat/${chatId}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (<div className="home-content">
    <div>
      <h2>{assignmentData.topic}</h2>
    </div>

    <div  className='bubble'>
      <h3>Assignment {assignmentData.number} Week {assignmentData.week}, Class {classId}</h3>
      <h4>Completion rate: {assignmentData.completed_count} / {assignmentData.total_students}</h4>

      <h4>Short Title:</h4>
      <p>{assignmentData.title}</p>

      <h4>Long Title:</h4>
      <p>{assignmentData.topic}</p>

      <h4>Purpose:</h4>
      <p>{assignmentData.purpose}</p>

      <h4>Description:</h4>
      <p>{assignmentData.description}</p>

      <h4>Example:</h4>
      <p>{assignmentData.example}</p>
    </div>

    <h3>Student-level overview:</h3>
    {
      assignmentData.student_completions.map(student =>
          (
              <div className='bubble interactive' onClick={() => handleChatClick(student.chat_id)}>
                <h4>{student.surname}, {student.name}</h4>
                <p>
                  {student.user_messages > 5 && (
                      <span>🟢  </span>
                  )}
                  {student.user_messages > 0 && student.user_messages <= 5 && (
                      <span>🟠  </span>
                  )}
                  {student.user_messages === 0 && (
                      <span>🔴  </span>
                  )}
                    Messages sent: {student.user_messages}</p>
              </div>
          )
      )
    }


  </div>);
};

export default ClassOverview;