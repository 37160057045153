import Cookies from 'js-cookie';
const serverUrl = process.env.REACT_APP_SERVER_URL;

function authPost(endpoint, data) {
  return fetch(serverUrl + endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${Cookies.get('token')}-${Cookies.get('user_id')}`,
    },
    body: JSON.stringify(data),
  });
}

export default authPost;