import React from 'react';

// Note that extraElement is in case we want to add some additional component in the bubble
const InputField = ({ label, value, onChange, editMode, extraElement }) => (
    <div className="bubble input">
      <label>{label}</label>
      {editMode ? (
          <div>
        <textarea
            required
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              e.target.style.height = 'inherit';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
        />
            {extraElement}
          </div>
      ) : (
          <p>{value}</p>
      )}
    </div>
);

export default InputField;