import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import authPost from "../../functions/authPost";
import authGet from "../../functions/authGet"; // Import authGet function
import TextInputField from './TextInputField';


const CreateAssignment = () => {
  const { class_id } = useParams(); // Fetch class_id from the URL
  const [editMode, setEditMode] = useState(true);

  // Load initial values from session storage or set to default
  const [title, setTitle] = useState(sessionStorage.getItem('title') || '');
  const [topic, setTopic] = useState(sessionStorage.getItem('topic') || '');
  const [purpose, setPurpose] = useState(sessionStorage.getItem('purpose') || '');
  const [description, setDescription] = useState(sessionStorage.getItem('description') || '');
  const [example, setExample] = useState(sessionStorage.getItem('example') || '');
  const [strategy, setStrategy] = useState(sessionStorage.getItem('strategy') || '');
  const [week, setWeek] = useState(sessionStorage.getItem('week') || 1);
  const [number, setNumber] = useState(sessionStorage.getItem('number') || 1);

  const navigate = useNavigate();

  // Save to session storage whenever the state changes
  useEffect(() => {
    sessionStorage.setItem('title', title);
  }, [title]);

  useEffect(() => {
    sessionStorage.setItem('topic', topic);
  }, [topic]);

  useEffect(() => {
    sessionStorage.setItem('purpose', purpose);
  }, [purpose]);

  useEffect(() => {
    sessionStorage.setItem('description', description);
  }, [description]);

  useEffect(() => {
    sessionStorage.setItem('example', example);
  }, [example]);

  useEffect(() => {
    sessionStorage.setItem('strategy', strategy);
  }, [strategy]);

  useEffect(() => {
    sessionStorage.setItem('week', week);
  }, [week]);

  useEffect(() => {
    sessionStorage.setItem('number', number);
  }, [number]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const assignmentFields = {
      class_id,
      title,
      topic,
      purpose,
      description,
      example,
      strategy,
      week,
      number,
    };

    try {
      const response = await authPost(`/teacher/${class_id}/create_assignment`, assignmentFields);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log(result);

      // Handle successful response
      alert('Assignment created successfully!');
      setEditMode(false);
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create assignment. Please try again.');
    }
  };

  const handleGenerateStrategy = async () => {
    const assignmentFields = {
      purpose,
      description,
      example,
    };

    try {
      const response = await authPost('/teacher/generate_strategy', assignmentFields);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setStrategy(result.strategy);
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to generate strategy. Please try again.');
    }
  };

  const handleTryChat = async (e) => {
    e.preventDefault();
    const assignmentFields = {
      class_id,
      title,
      topic,
      purpose,
      description,
      example,
      strategy,
      week,
      number,
    };

    try {
      const response = await authPost(`/teacher/try-chat`, assignmentFields);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const newChatId = result.id;

      setEditMode(false);
      navigate(`/chat/${newChatId}?test-chat=True`); // Adjust the path as per your routing setup
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create assignment. Please try again.');
    }
  };

  return (
      <div className="assignment-container">
        <div className="assignment-header">
          <h2>Creating Assignment for Class ID: {class_id}</h2>
        </div>

        <form onSubmit={handleSubmit}>

          <div className="input-group">
            <label>Week</label>
            <input type="number" value={week} onChange={(e) => setWeek(e.target.value)} min="1" required/>
          </div>

          <div className="input-group">
            <label>Number</label>
            <input type="number" value={number} onChange={(e) => setNumber(e.target.value)} min="1" required/>
          </div>

          <TextInputField label="Short Title (max 3-5 words)" value={title} onChange={setTitle} editMode={editMode} />
          <TextInputField label="Long TItle (up to 5-10 words)" value={topic} onChange={setTopic} editMode={editMode} />
          <TextInputField label="Purpose" value={purpose} onChange={setPurpose} editMode={editMode} />
          <TextInputField label="Description" value={description} onChange={setDescription} editMode={editMode} />
          <TextInputField label="Example" value={example} onChange={setExample} editMode={editMode} />
          <TextInputField label="Strategy" value={strategy} onChange={setStrategy} editMode={editMode}
              extraElement={
                <button type="button" onClick={handleGenerateStrategy}>
                  Generate Strategy
                </button>
              }
          />

          <button type="button" onClick={() => setEditMode(!editMode)}>
            {editMode ? 'Confirm' : 'Edit'}
          </button>
          <div>
            {!editMode && (
                <div>
                  <p>Please make sure that the information above is correct before submitting the assignment to the students.</p>
                  <button type="button" onClick={handleTryChat}>
                    Try Chat
                  </button>
                  <button type="submit">Submit Assignment</button>
                </div>
            )}
          </div>
        </form>
      </div>
  );
};

export default CreateAssignment;